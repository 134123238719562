// MiComponente.tsx
import React from 'react';
import i18next from "i18next";
import Button from './ui/Button';
import { useTranslation } from "react-i18next";
import { getAvailability } from '@services/availability.service';
import Loading from './ui/Loading';

const currentLang = i18next.language;

interface Props {
	id: string;
	modelo: string;		
	etiquetas: string;	
	link_ca: string;
	link_es: string;
	codigo_producto: string;
	estacion: string,
}

export interface Availability {
	cantidad : number
	cache: Boolean
}

const TyreAvailabilityDialog: React.FC<Props> = ({ 
	id,
	modelo,
	etiquetas, 
	link_ca, 
	estacion,
	link_es,
	codigo_producto
}) => {
	const { t, i18n } = useTranslation("translation");
	const [cantidad, setCantidad] = React.useState(0);
	const [available, setAvailable] = React.useState(true);
	const [showAvailable, setShowAvailable] = React.useState(false);
	const [showNotAvailable, setShowNotAvailable] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [availability, setAvailability] = React.useState<Availability>();



	React.useEffect(() => {
			
	}, []);


	const handleBuyButton = async () => {
		setLoading(true)
		const variable = await getAvailability(codigo_producto, estacion)
		console.log(variable)
		setAvailability(variable)
		if(variable.cantidad >= 4){
			window.location.href = currentLang === "ca" ? link_ca : link_es
			setLoading(false)
		} else if ( variable.cantidad < 4 && variable.cantidad >= 2){
			setShowAvailable(true);			
			setLoading(false)
		} else {
			setShowNotAvailable(true);
			setAvailable(false);
			setLoading(false)
		}
	}

	return ( 
		
		<div className='w-full align-middle'>
			{loading ?  (<div className='flex justify-center align-middle w-full'><Loading /></div>) :(
			<a className={available ? "flex justify-center w-full text-2xl" : "flex justify-center w-full text-md"} onClick={handleBuyButton} rel="nofollow">
				<Button disabled={!available} ariaLabel={t("index.priceCard.otherButton")} styles={available ? "w-[100%]" : "w-[100%] bg-white"}>
				{
					available ? t("index.priceCard.buy") : t("index.priceCard.notAvailable") 
				}

				</Button>
			</a>
	)}
			{showAvailable &&
				<div>
					<div className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60" />
					<div className="fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-192 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg">
						<h1 className="text-2xl font-semibold">
							{t("kioskModal.imInterestedModalTitle")}
						</h1>
						<div className="py-5 border-t border-b border-gray-300 text-center">		
							<p className="text-center">
								<span>{t("index.priceCard.selectedProduct")+" " }</span>
								<span className="capitalize font-bold">{modelo+" "}</span>  
								<span className="capitalize font-bold">{etiquetas}</span>
								<br />

								<span>{t("index.priceCard.stock")}</span>
								<br />
								<span>{t("index.priceCard.continue")}</span>								
							</p>
						</div>
					
						<div className="flex justify-around">
							<a	href={currentLang === "ca" ? link_ca : link_es}	rel="nofollow">
								<button	className="group bg-[#FFD100] text-gray-700 rounded uppercase font-bold text-center py-2 px-4 flex items-center justify-center gap-2 md:hover:bg-opacity-80">
									{t("index.priceCard.yes")}
								</button>
							</a>

							
							<button	onClick={() => setShowAvailable(false)} className="group bg-[#FFD100] text-gray-700 rounded uppercase font-bold text-center py-2 px-4 flex items-center justify-center gap-2 md:hover:bg-opacity-80">
								{t("index.priceCard.no")}
							</button>
						</div>
					</div>									
				</div>
			}
			{showNotAvailable &&
				<div>
					<div className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60" />
					<div className="fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-192 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg">
						<h1 className="text-2xl font-semibold">
							{t("kioskModal.imInterestedModalTitle")}
						</h1>
						<div className="py-5 border-t border-b border-gray-300 text-center">		
							<p className="text-center">
							<span>{t("index.priceCard.selectedProduct")+" " }</span>
							<span className="capitalize font-bold">{modelo+" "}</span>
							<span className="capitalize font-bold">{etiquetas}</span>
							<br />
							<span>{t("index.priceCard.notAvailableMessage")}</span>
							</p>
						</div>
						<div className="flex justify-around">
							<button	onClick={() => setShowNotAvailable(false)} className="group bg-[#FFD100] text-gray-700 rounded uppercase font-bold text-center py-2 px-4 flex items-center justify-center gap-2 md:hover:bg-opacity-80">
								{t("index.priceCard.close")}
							</button>
						</div>
					</div>
				</div>
			}				
				
		</div>	
	);				
		
}
export default TyreAvailabilityDialog;

